@import "../constants.less";
.base-content-main-header-background {
  height: 48px;
}
.base-content-main-header {

  position: absolute;
  top: 12px;
  box-sizing: border-box;
  //width: 100%;
  width: ~"calc(100% - 15px)";
  height: 48px;
  line-height: 48px;
  padding-right: @padding-size-big;
  @media screen and (max-width: @small-screen) {
    padding-right: @padding-size;
  }

  background: #fff;
  font-weight: bold;
  z-index: 99;

  .base-content-main-header-inner {
    height: 48px;
    border-bottom: 1px solid #dfe5e7;
    overflow: hidden;
    .antdx-content-header-title {
      float: left;
      font-size: 15px;
      color: #191f25;
      margin-left:15px;
      &:before {
        content: "";
        width: 4px;
        height: 20px;
        margin-right: 8px;
        background-color: #34cb82;
        display: inline-block;
        vertical-align: text-bottom;
      }
    }
    .antdx-content-header-btns {
      float: right;
      .ant-btn{
        margin-left: 10px;
        height: 30px;
      }
    }
    .antdx-content-header-btns:after{
      content: '';
      display: block;
      clear: both;
    }
  }
}

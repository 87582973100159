// @form-item-height: 32px; // 表单高度控制
@import (reference) "../constants.less";

.base-content-main-bottom-actions {
  .bottom-actions {
    position: absolute;
    left: 10px;
    bottom: -45px;
    .ant-form-inline {
      height: @form-item-height;
      .ant-form-item {
        margin-right: 10px;
        height: @form-item-height;
        .ant-form-item-label {
          height: @form-item-height;
          line-height: @form-item-height;
        }
        .ant-form-item-control {
          height: @form-item-height;
          line-height: @form-item-height;
        }
      }
    }
  }
}


// table页样式调整
.my-exam
{
  margin-top: 15px;
  background-color: #fff;
  .table-list
  {
    .ant-table-thead{
      background-color: #eeeeee!important;
    }
  }
  // 搜索
  .searchForm{
    padding:8px 0;
    margin-left: 12px;
    .formSelect{
      width: 240px;
    }
    .formInput{
      width: 220px;
    }
    .formTreeSelect{
      width: 240px;
    }
    .formButton{
      .fromBtn{
        margin-left: 10px;
      }
    }
    .createBtn{
      height: 30px;
    }
    .searchRightBtn{
      margin-right: 15px;
    }
  }
}

// modal页样式调整
.activity-modal
{
  .ant-modal-content{
    .ant-modal-body{
      overflow-y: auto;
      max-height: 65vh;
      margin-right: 2px;
    }
  }
  .ant-form-item{
    margin-bottom: 12px;
  }
}



.my-exam-activity{
  background-color: #ffffff;
  height: ~"calc(100vh - 152px)";
  padding: 20px;
  overflow-x: auto;
  .countDown{
    width: 100%;
    display: flex;
    height: 300px;
    justify-content: center;
    align-content: center;
    margin-top: 42px;
    .ant-statistic{
      .ant-statistic-title{
        font-size: 24px;
      }
      .ant-statistic-content{
        width: 500px;
        height: 120px;
        line-height: 120px;
        border-radius: 12px;
        font-size: 32px;
        color: #ffffff;
        border: 4px solid #ccc;
        background-color: #242424;
        box-sizing: content-box;
        text-align: center;
      }
    }
  }

}
.activity-exam-list{
  width: 100%;
  background-color: #ffffff;
  padding: 0 28px;
  height: ~"calc(100vh - 125px)";
  overflow: auto;
  .exam-list{
    width: 100%;
    .list-header{
      font-size: 16px;
      color: #242424;
      font-weight: 700;
    }
    .list-title{
      width: 100%;
      white-space:normal!important;
      .list-title-label{
        float: left;
        width: 30px;
        font-size: 16px;
        margin-top: -2px;
        font-weight: 700;
      }
      .list-title-name{
        float: left;
        width: ~"calc(100% - 230px)";
        padding-left: 10px;
        img{
          max-width: 420px!important;
        }
      }
      .list-title-score{
        float: right;
        width: 200px;
        padding-right: 30px;
        color: red;
      }
      .list-title-score-1{
        color: #999;
      }
    }
    .list-title:after{
      content: '';
      display: block;
      clear: both;
    }
    .ant-list-item-meta-description{
      .ant-checkbox-group,.ant-radio-group{
        width: 100%;
        .list-info{
          display: flex;
          justify-content:flex-start;
          align-items:flex-start;
          margin-bottom: 8px;
          width: 100%;
          .list-label{
            width: 30px;
          }
          .list-radio{
            margin: 0;
            width: ~"calc(100% - 40px)";
            display: flex;
            justify-content:flex-start;
            align-items:flex-start;
            .ant-checkbox,.ant-radio{
              margin-top: 3px;
            }
            .list-radio-value{
              img{
                max-width: 420px!important;
              }
              white-space:normal!important;
            }
          }
        }
        .list-judge-radio{
          margin-left: 10px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .answer{
    margin-top: 20px;
    color: #242424;
    .exactness,.analysis{
      .label{
        color: red;
      }
    }
  }
}

.exam-result{
  background-color: #ffffff;
  height: ~"calc(100vh - 110px)";
  display: flex;
  justify-content: center;
  flex-direction:column;
  .ant-result-icon{
    .anticon-smile{
      font-size: 150px;
    }
  }
  .ant-result-title{
    color: #999999;
    font-size: 50px;
  }
}
.base-content-main-header-data{
  .exam-activity-button{
    float: left;
    margin-right: 30px;
    margin-top: 10px;
  }
}
.base-content-main-header-data:after{
  content: '';
  display: block;
  clear: both;
}
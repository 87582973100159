.header{
    position: fixed;
    z-index: 999;
    height: 50px;
    width: ~"calc(100% - 80px)";
    background-color: #ffffff;
    .header-top{
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        text-align: right;
        //border-bottom: 1px solid #40AEFC;
        .navStatus{
            text-align: left;
            .navStatusButton{
                margin-bottom: 16px;
                text-align:left
            }
        }
        .logo{
            line-height: 50px;
            text-align: left;
            font-size: 18px;
            img{
                height: 40px;
                vertical-align: middle;
            }
            span{
                margin-left: 5px;
            }
        }

        .weather{

            .logout{
                margin-left: 15px;
            }
            .setting{
                margin-left: 15px;
                font-size: 18px;
            }
            .setting:hover{
                color:#1890FF;
            }
        }
    }
}
.simple-page{
    .header-top{
        background:#1890ff;
        color:#ffffff;
    }
}
// 下拉设置样式
.settingDropDown {
    .ant-dropdown-menu{
        margin-top: 12px
    }
    .MenuItemIcon{
        margin-left: 3px;
    }
    .MenuItemI{
        margin-left: -3px;
    }
};
.header-action{
    width: ~"calc(100% - 240px)";
}

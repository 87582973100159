
*{
    margin: 0;
    padding: 0;
}
html {
    overflow-y: hidden;
}
body{
    min-width: 1024px;
}
ul,li{
    list-style: none;
}
.clearfix{
    &::after{
        content:' ';
        clear:both;
        display: block;
        visibility: hidden;
    }
}
.container{
    .nav-left{
        background-color:#001529;
        color: #ffffff;
        height: calc(100vh);
    }
    .main{
        height: calc(100vh);
        background-color: #f1f3f5;
        overflow: hidden;
    }
    .content{
        //position: relative;
        margin-top: 50px;
        padding: 12px;
        padding-right: 0;
        height: ~"calc(100vh - 50px)";
        overflow: auto;
    }
}

.content-wrap{
    background: #ffffff;
    border: 1px solid #e8e8e8;
    margin-top: -3px;
    .ant-table-wrapper{
        margin-left: -1px;
        margin-right: -2px;
    }
}
.ant-card{
    button{
        margin-right: 10px;
    }
}
// 全局滚动条
::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track{
    border-radius: 4px;
}
::-webkit-scrollbar-thumb{
    border-radius:4px;
    background: rgba(57, 65, 72, 0.57);
}


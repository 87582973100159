.container{
    .nav-left{
        width: 240px;
        min-height: 240px;
        .logo{
            line-height: 90px;
            background-color: #002140;
            //background-color: #242424;
            text-align: left;
            padding-left: 18px;
            img{
                width: 45px;
                border-radius: 8px;
            }
            h1{
                color: #ffffff;
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
                margin: 0 0 0 10px;
            }
        }
    }
    .nav-left-shrink{
        width: 80px!important;
    }
    .main-expand{
        width: ~"calc(100% - 80px)";
    }

    .menuLeft.ant-menu.ant-menu-dark.ant-menu-inline-collapsed{
        height: ~"calc(100vh - 90px)";
    }
    .menuLeft.ant-menu.ant-menu-dark{
        height: ~"calc(100vh - 95px)";
        overflow-y: auto;
        //隐藏滚动条
        -ms-overflow-style: none; //IE 10+
        scrollbar-width: none;//Firefox
    }
    //隐藏滚动条
    .menuLeft.ant-menu.ant-menu-dark::-webkit-scrollbar{
        display:none
    }
}


@media screen and (min-width:800px) and (max-width:1100px){

}
@media screen and (max-width:800px){
    .container{
        .nav-left{
            .logo {
                .logoInfo{
                    display: none;
                }
            }
        }
    }
}